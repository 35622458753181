import type { DomainsConfig } from './hooks/config'
import { SITE_ENV } from './common'

const DOMAIN_CONFIG_DEV: DomainsConfig = {
  'fedefarma.dev': {
    GATEWAY_BASE_URL: 'https://gateway.fedefarma.dev',
    OAUTH_BASE_URL: 'https://accountsbackend.fedefarma.dev',
    ROOT_SITE: 'https://appcloud.fedefarma.dev'
  },
  'farmacloud.io': {
    GATEWAY_BASE_URL: 'https://gateway-dev.farmacloud.io',
    OAUTH_BASE_URL: 'https://accountsbackend-dev.farmacloud.io',
    ROOT_SITE: 'https://dev.farmacloud.io'
  },
  'appcloud.io': {
    GATEWAY_BASE_URL: 'https://gateway-dev.appcloud.io',
    OAUTH_BASE_URL: 'https://accountsbackend-dev.appcloud.io',
    ROOT_SITE: 'https://dev.appcloud.io'
  },
  'default': {
    GATEWAY_BASE_URL: 'https://gateway-dev.farmacloud.io',
    OAUTH_BASE_URL: 'https://accountsbackend-dev.farmacloud.io',
    ROOT_SITE: 'https://dev.farmacloud.io'
  }
}
  
const DOMAIN_CONFIG_STG: DomainsConfig = {
  'farmacloud.io': {
    GATEWAY_BASE_URL: 'https://gateway-stg.farmacloud.io',
    OAUTH_BASE_URL: 'https://accountsbackend-stg.farmacloud.io',
    ROOT_SITE: 'https://stg.farmacloud.io'
  },
  'appcloud.io': {
    GATEWAY_BASE_URL: 'https://gateway-stg.appcloud.io',
    OAUTH_BASE_URL: 'https://accountsbackend-stg.appcloud.io',
    ROOT_SITE: 'https://stg.appcloud.io'
  },
  'default': {
    GATEWAY_BASE_URL: 'https://gateway-stg.farmacloud.io',
    OAUTH_BASE_URL: 'https://accountsbackend-stg.farmacloud.io',
    ROOT_SITE: 'https://stg.farmacloud.io'
  },
}

const DOMAIN_CONFIG_PRO: DomainsConfig = {
  'farmacloud.io': {
    GATEWAY_BASE_URL: 'https://gateway-dev.farmacloud.io',
    OAUTH_BASE_URL: 'https://accountsbackend-dev.farmacloud.io',
    ROOT_SITE: 'https://dev.farmacloud.io'
  },
  'appcloud.io': {
    GATEWAY_BASE_URL: 'https://gateway.appcloud.io',
    OAUTH_BASE_URL: 'https://accountsbackend.appcloud.io',
    ROOT_SITE: 'https://app.appcloud.io'
  },
  'default': {
    GATEWAY_BASE_URL: 'https://gateway-dev.farmacloud.io',
    OAUTH_BASE_URL: 'https://accountsbackend-dev.farmacloud.io',
    ROOT_SITE: 'https://dev.farmacloud.io'
  },
}

let DOMAIN_CONFIG: DomainsConfig
if (SITE_ENV === 'dev') {
  DOMAIN_CONFIG = DOMAIN_CONFIG_DEV
} else if (SITE_ENV === 'stg') {
  DOMAIN_CONFIG = DOMAIN_CONFIG_STG
} else if (SITE_ENV === 'pro') {
  DOMAIN_CONFIG = DOMAIN_CONFIG_PRO
}

export {
  DOMAIN_CONFIG,
}
  