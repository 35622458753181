import type { Environment } from '../common'
import type { Config, UrlsAddressess, AllowedDomain, BaseConfigObject } from './config'
import { DOMAIN_CONFIG } from './../domains'

const createConfig = (data: Config, env: Environment, domain: AllowedDomain) => {
  // const domain = window.location.hostname.split('.').splice(-2).join('.') as AllowedDomain
  let SELCTED_CONFIG: BaseConfigObject = {
    OAUTH_BASE_URL: '',
    GATEWAY_BASE_URL: '',
    ROOT_SITE: ''
  }
  if (DOMAIN_CONFIG[domain]) {
    SELCTED_CONFIG = DOMAIN_CONFIG[domain]
  }

  const {
    OAUTH_BASE_URL,
    GATEWAY_BASE_URL,
    ROOT_SITE,
  } = SELCTED_CONFIG
  
  let k: keyof UrlsAddressess
  for (k in data.urls) {
    data.urls[k] = data.urls[k].replace('{OAUTH_BASE_URL}', OAUTH_BASE_URL)
    data.urls[k] = data.urls[k].replace('{GATEWAY_BASE_URL}', GATEWAY_BASE_URL)
    data.urls[k] = data.urls[k].replace('{ROOT_SITE}', ROOT_SITE)
  }
  return data
}
export {
  createConfig
}