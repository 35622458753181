import { Icon, Text, Box, Tag, Btn } from '../common'
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { userInfoData } from '../../hooks/useAuth';
// import { Feed } from './NotificationsHook'
import Slider from "react-slick"
import { UrlsAddressess } from '../../hooks/config'
import { getFormatDate, getFormatTime } from '../../utils/date'
import { useFeeds, Feed, Notification } from './UseFeeds'
import { ReactElement, useState, useEffect, createRef, useRef, useMemo } from 'react';


export const NotificationsPanel = ({
    onSettingClick,
    userInfo,
    userToken,
    closeDropdown,
    urls
  }: {
    onSettingClick: () => void
    closeDropdown: () => void
    userInfo: userInfoData
    userToken: string
    urls: UrlsAddressess
  }) => {
    const {
      selectFeed,
      fetchNextPage,
      selectIsRead,
      unReadCountTab,
      feeds,
      hasNextPage,
      notifications,
      isLoading,
      isRead,
      markAsRead,
      markAllAsRead,
      deleteNotification,
      deleteAllNotifications,
      markAsUnRead,
      selectedFeed
    } = useFeeds({ userToken: userToken, urls:urls })

    return (
      <div>
        <Header
          markAllAsRead={markAllAsRead}
          unReadCountTab={unReadCountTab}
          onSettingClick={onSettingClick}
          userInfo={userInfo}
          isRead={isRead}
          selectIsRead={selectIsRead}
          deleteAllNotifications={deleteAllNotifications}
          feeds={feeds}
          selectFeed={selectFeed}
          selectedFeed={selectedFeed}
          closeDropdown={closeDropdown}
        />
        <Body
          notifications={notifications}
          isLoading={isLoading}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          markAsRead={markAsRead}
          deleteNotification={deleteNotification}
          isRead={isRead}
          userInfo={userInfo}
          markAsUnRead={markAsUnRead}
          selectedFeed={selectedFeed}
        />
      </div>
    );
  }
  
  
  const Header = ({
    markAllAsRead,
    onSettingClick,
    unReadCountTab,
    userInfo,
    isRead,
    selectIsRead,
    deleteAllNotifications,
    feeds,
    selectFeed,
    selectedFeed,
    closeDropdown
  }: {
    markAllAsRead: () => void,
    unReadCountTab: string,
    onSettingClick: () => void,
    userInfo: userInfoData,
    isRead: boolean,
    feeds: Feed[],
    selectIsRead: (read: boolean) => void,
    deleteAllNotifications: () => void,
    selectFeed: (feed: Feed) => void,
    selectedFeed: Feed,
    closeDropdown: () => void,
  }) => {
    const [hasArrows, setHasArrows] = useState<boolean>(false)
    return (
      <Box className='notifications-header'>
        <Box className="notifications-header-top">
          <Text
            color="white"
            weight="medium"
            size="xl"
            >{userInfo.translations.notifications_inbox_title}
          </Text>
          <Box className='notification-hader-right'>
            <Icon
              onClick={onSettingClick}
              title="Ajustes"
              color="white"
              className="notifications-settings"
              name="icon-settings-2-outline"
              style={{ cursor:'pointer'}}
            />
            <Icon 
              style={{
                color: 'white',
                cursor:'pointer'
              }}
              size={28}
              onClick={closeDropdown}
              name="icon-close"
            />
          </Box>
        </Box>
        <Box length={feeds.length} className="notifications-header-tags">
          {
            feeds.length && (
              <div className='slider-container-tag'>
                <Slider {...{
                    appendDots:(dots: ReactElement[]) => {
                      setHasArrows((dots?.length) ? true : false)
                      return <div></div>
                    },
                    dots: true,
                    infinite: false,
                    autoplay: false,
                    speed: 500,
                    slidesToScroll: 1,
                    slidesToShow: 4,
                    variableWidth: true,
                    prevArrow: <div></div>,
                    nextArrow: (hasArrows) ? <div><Icon className="bg-background" size="25" name="icon-arrow-down"/></div> : <div></div>
                  }}>
                  <Tag
                    key={selectedFeed.identifier}
                    color="primary"
                    className={`tag-${selectedFeed.name}`}
                    onClick={() => selectFeed(selectedFeed)}
                  >{ selectedFeed.name}</Tag>
                {
                  feeds.filter((f) => f.identifier !== selectedFeed.identifier).map((feed) => {
                    return (
                      <Tag
                        key={feed.identifier}
                        className={`slider-tag tag-${feed.name}`}
                        color="dark-grey"
                        onClick={() => selectFeed(feed)}
                      >{feed.name}</Tag>
                  )
                  })
                }
                </Slider>
              </div>
            )
          }
        </Box>
        <Box className="notifications-header-bottom">
            <Box>
              <Text
                onClick={() => selectIsRead(false)}
                size="md"
                weight="medium"
                className={(isRead ? 'tab unread-tab' : 'tab unread-tab active')}
              > { userInfo.translations.notifications_inbox_tab_unread}
                { unReadCountTab && (
                  <Text
                    className="notifications-unread"
                    bgColor="primary"
                    color="white"
                    weight="medium"
                  >
                    {unReadCountTab}
                  </Text>
                )}
              </Text>
              <Text
                className={(isRead ? 'tab read-tab active' : 'tab read-tab')}
                onClick={() => selectIsRead(true)} 
                size="md" 
                weight="medium"
              >{ userInfo.translations.notifications_inbox_tab_read}</Text>
            </Box>
            {
              (isRead) ? 
              (<Text className="tab tab-icon" size="md" weight="medium" onClick={deleteAllNotifications}> 
                
                {userInfo.translations.notifications_set_as_remove}
                <Icon 
                   style={{
                     cursor:'pointer'
                   }}
                   name="icon-delete"
                   size={20}
                 />
              </Text>)
              : (
                <Text className="tab tab-icon" size="md" weight="medium" onClick={markAllAsRead}>
                 {userInfo.translations.notifications_set_as_read}
                 <Icon 
                   style={{
                     cursor:'pointer'
                   }}
                   name="icon-email-open"
                   size={20}
                 />
                </Text>
              )
            }
        </Box>
      </Box>
    )
  }
  
  const Body = ( {
    notifications,
    isLoading,
    hasNextPage,
    isRead,
    userInfo,
    fetchNextPage,
    markAsRead,
    deleteNotification,
    markAsUnRead,
    selectedFeed
  }: {
    notifications: Notification[] | undefined,
    isLoading: boolean,
    hasNextPage: boolean | undefined
    isRead: boolean
    userInfo: userInfoData
    fetchNextPage: () => void
    markAsRead: (notification: Notification) => void
    markAsUnRead: (notification: Notification) => void
    deleteNotification: (notification: Notification) => void
    selectedFeed: Feed
  } ) => {
    const [sentryRef] = useInfiniteScroll({
      loading: isLoading ,
      hasNextPage: hasNextPage || false,
      onLoadMore: fetchNextPage,
      rootMargin: '0px 0px 20px 0px',
    });
    const bodyRef = createRef<HTMLDivElement>()
    const [classes, setClasses] = useState<string>('')
    // const [addTrans, setAddTrans] = useState<boolean>(false)

    useEffect(() => {
      bodyRef.current?.scrollTo(0, 0)
    }, [isRead, selectedFeed])

    useEffect(() => {
      setClasses('notifications-body-transitions')
      setTimeout(() => {
        setClasses('')
      }, 1200)
    }, [notifications])

    return (
      <Box
        ref={bodyRef}
        className={`notifications-body ${classes}`}
      >
       
      {
        Array.isArray(notifications) && notifications?.length > 0 ? (
          <div className="notifications-body-inner">
            {notifications?.map((notification: Notification) => {
              return (
                <NotificationItem
                  key={notification._id}
                  notification={notification}
                  markAsRead={markAsRead}
                  deleteNotification={deleteNotification}
                  userInfo={userInfo}
                  markAsUnRead={markAsUnRead}
                />

              )
            })}
  
            <div className='sentry' style={{height: '1px' }}ref={sentryRef}/>
            {isLoading && (
              <div className='notification-is-loading'>{userInfo.translations.notifications_loading + String(isLoading) + String(hasNextPage)} </div>
            )}
          </div>
          ) : (
          <Box
            className="notification-empty-message"
          >
            <EmptyList isRead={isRead} userInfo={userInfo}></EmptyList>
          </Box>)
      }
    </Box>
  
    )
  }
  
  const NotificationItem = ({
    notification,
    markAsRead,
    deleteNotification,
    userInfo,
    markAsUnRead,
  }: {
    notification: Notification,
    markAsRead: (notification: Notification) => void
    markAsUnRead: (notification: Notification) => void
    deleteNotification: (notification: Notification) => void
    userInfo: userInfoData
  }) => {
    const payloadData = useMemo(() => notification.payload[userInfo.language] || {}, [userInfo.language, notification.payload])
    const title = useMemo(() => payloadData.title || '', [payloadData])
    const body = useMemo(() => payloadData.body || '', [payloadData])

    // Get format date
    const formatDateTime = useMemo(() => getFormatDate(notification.createdAt), [notification.createdAt])
    const fmtTime = getFormatTime(notification.createdAt, {
      lessOneHour: userInfo.translations.notifications_less_one_hour
    })

    const [isReadChange, setIsReadChange] = useState<boolean>(false)
    const firstUpdateRead = useRef(false);
    useEffect(() => {
      if (firstUpdateRead.current) {
        setIsReadChange(true)
      }
      firstUpdateRead.current = true
    }, [notification.read])

    const [isDeletingChange, setIsDeletingChange] = useState<boolean>(false)
    const firstUpdateDeleting = useRef(false);
    useEffect(() => {
      if (firstUpdateDeleting.current) {
        setIsDeletingChange(true)
      }
      firstUpdateDeleting.current = true
    }, [notification.deleting])
    return (
        <Box
          className={
            `notifications-notification ${(notification.read) ? 'is-read' : 'not-read'} 
             ${(isReadChange) ? 'notification-exit-transition' : 'not-change'} 
             ${(isDeletingChange) ? 'notification-exit-transition ' : 'not-change'} 
            `
          }
        >
          <Box className="line"></Box>
          <Box className="avatar" style={{backgroundImage: `url("${notification.actor?.data}")`}}></Box>
          <Box className='content'>
            <Box
              className="content-top"
            >
              <Box className="content-top-left">
                  <Text className="title" tag="p"><b dangerouslySetInnerHTML={{ __html: title }} /></Text>
                  <Text className="body" tag="p" dangerouslySetInnerHTML={{ __html: body }} />
              </Box>
              <Box className="content-top-right">
                {
                  !notification.read &&
                  (<Icon
                    onClick={() => markAsRead(notification)}
                    title={userInfo.translations.notifications_set_as_read_tip}
                    className="close icon-email-open"
                    name="icon-email-open"
                    size={20}
                    color="white"
                    style={{
                      cursor: 'pointer'
                    }}
                  />)
                }
                {
                  notification.read && (
                    <div>
                      <Icon
                        onClick={() => markAsUnRead(notification)}
                        title={userInfo.translations.notifications_set_as_unread_tip}
                        className="close icon-email-bullet"
                        name="icon-email-bullet"
                        size="xl"
                        style={{
                          cursor: 'pointer',
                          marginRight: '5px',
                        }}
                      />
                      <Icon
                        onClick={() => deleteNotification(notification)}
                        title={userInfo.translations.notifications_set_as_remove_tip}
                        className="close icon-delete"
                        name="icon-delete"
                        size="xl"
                        style={{
                          cursor: 'pointer'
                        }}
                      />
                    </div>
                  )
                }
              </Box>
            </Box>
            <Box className="content-bottom">
                <Box className="content-bottom-left">
                  {
                    notification.cta && notification.cta?.action?.buttons && (
                      <Box className="button-box">
                        {
                          notification.cta?.action?.buttons.map((button) => {
                            return (
                              <Btn
                                key={button.type}
                                color={button.type}
                                underline={false}
                                weight="bold"
                                className="action-button"
                                onClick={() => {
                                  window.open(payloadData[button.type], '_blank')
                                }}
                              >
                                {button.content}
                              </Btn>  
                            )
                          })
                        }
                      </Box>
                    )
                  }
                </Box>
                <Box className="content-bottom-right">
                  <Text className="received-time" title={formatDateTime} size="xs">{fmtTime || formatDateTime}</Text>
                </Box>
            </Box>
          </Box>
        </Box>
    )
  }


  const EmptyList = ({ isRead, userInfo }: { isRead: boolean, userInfo: userInfoData}) => {
    return (<Box className="empty-list">
      <Box className="empty-list-icon">
        <Icon name="icon-bell" color="primary" size="82"/>
      </Box>
      <Box>
      <Text className="empty-list-text-title"  size="xl" weight="bold"> 
      {
        isRead ? userInfo.translations.notifications_empty_unread_notifications : userInfo.translations.notifications_empty_notifications
      }
      </Text>
      <Box className="empty-list-text-description">
        <Text>{ userInfo.translations.notifications_empty_message }</Text>
      </Box>
      </Box>
    </Box>)
  }