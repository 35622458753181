
export type Environment = 'dev' | 'stg' | 'pro'
export type Theme = 'light' | 'dark'

export const URL_BASE = process.env.REACT_APP_CONFIG_BASE_URL
export const SITE_ENV = process.env.REACT_APP_SITE_ENV as Environment

export type TopbarMenuItem = {
  id?: string
  type: 'event' | 'menu'
  text: string
  eventName?: string
  iconLeft?: string
  iconRight?: string
  tag?: string
  disabled?: boolean,
  active?: boolean,
  clickDisabled?: boolean,
  closeOnClick?: boolean,
  activeMode?: 'click' | 'hover',
  menuWidth?: number | string,
  options?: TopbarMenuItem[],
  data?: any
}

export type TopbarMenu = {
  text?: string
  version?: string,
  theme?: Theme,
  menuWidth?: number | string,
  activeMode?: 'click' | 'hover',
  options: TopbarMenuItem[]
} | null | undefined

export type Delegation = {
  uuid: string,
  organization_id?: number,
  parent_id?: number,
  name: string,
  delegation_identifier?: string,
  data?: string,
  delegation_type?: {
    id: number,
    name: string,
    has_profile: boolean
  }
}

export type DelegationsParent = {
  [key: string]: {
    delegations: Delegation[],
    customization: {
      name: string,
      logo: string
    },
    delegation_root: {
      uuid: string,
      name: string,
      delegation_identifier?: string
    }
  }
}

export type Delegations = {
  parents?: DelegationsParent
}